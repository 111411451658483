import { useEffect, useState } from 'react';
import { Button, Divider, CardActions, CardContent, Modal, IconButton, Typography, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import MainCard from '../../berry/ui-component/cards/MainCard';

type ConfirmModalPropsType = {
    title: string;
    content: string;
    open: boolean;
    handleConfirm: () => void;
    handleCancel?: () => void;
    onClose: () => void;
};

const ConfirmModal = ({
    title,
    content,
    open,
    handleConfirm,
    handleCancel,
    onClose,
}: ConfirmModalPropsType): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(open);
    useEffect(() => setOpen(open), [open]);

    return (
        <Modal keepMounted={true} open={isOpen} onClose={onClose}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: { xs: 280, lg: 450 },
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                title={title}
                content={false}
                secondary={
                    <IconButton onClick={onClose} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                <CardContent>
                    <Typography variant="body1">{content}</Typography>
                </CardContent>
                <Divider />
                <CardActions>
                    <Stack justifyContent="flex-end" direction="row" sx={{ flex: 1 }}>
                        <Button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (handleCancel) handleCancel();
                                onClose();
                            }}
                            sx={{ marginRight: 3 }}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleConfirm();
                            }}
                        >
                            Confirmer
                        </Button>
                    </Stack>
                </CardActions>
            </MainCard>
        </Modal>
    );
};

export default ConfirmModal;
