/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import {
    FormEntity,
    FormInput,
    SubFormType,
    FormElementType,
    InputType,
    ImageConverter,
    CustomLoader,
} from 'm6BoBuilder';
import { getObjectProperty } from 'm6BoBuilder/utils/getObjectProperty';
import { FormikValues } from 'formik';
import Ingredient from 'config/resources/Ingredient';
import { INGREDIENT_UNITS } from '../../../constants';
import { useGetIngredient } from 'services/useQueries';

const configIngredient: FormElementType = {
    label: 'Ingrédient',
    property: 'ingredient',
    options: {
        resource: Ingredient.resourcePath,
        details: { path: 'unit', prelabel: '', values: INGREDIENT_UNITS },
    },
};
const configQuantity: FormElementType = {
    label: 'Quantité',
    property: 'quantity',
    type: InputType.float,
};

const SubformRecipeIngredient = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [id, setId] = useState();
    const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            ingredient: null,
            quantity: null,
            position: null,
        },
    );

    const { isFetching, data: ingredientData } = useGetIngredient(id);

    useEffect(() => {
        ingredientSelected();
    }, []);

    const ingredientSelected = () => {
        if (entity[configLine.property][position] !== undefined) {
            const { ingredient = null } = entity[configLine.property][position];
            if (!ingredient) {
                return;
            }
            const { id } = typeof ingredient === 'string' ? { id: ingredient.split('/').slice(-1)[0] } : ingredient;
            if (null === id || id === undefined) {
                return;
            }
            setId(id);
        }
    };

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        entity[configLine.property][position] = updatedSubEntity;

        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);

        ingredientSelected();
    };

    const getAdornmentFromUnit = (value?: string) => {
        if (!value) return undefined;
        const unit = parseInt(value);

        if (unit !== 0 && INGREDIENT_UNITS[unit]) {
            return <InputAdornment position="end">{INGREDIENT_UNITS[unit]}</InputAdornment>;
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={2}>
                {!isFetching && ingredientData
                    ? getObjectProperty(ingredientData, 'media', (ImageConverter.display as any) ?? null)
                    : ''}
                {isFetching && (
                    <Grid container justifyContent="center" alignItems="center">
                        <CustomLoader width={50} height={50} style={{ margin: 0 }} />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={6}>
                <FormEntity
                    entity={subEntity}
                    defaultValue={ingredientData}
                    updateEntity={updateSubEntity}
                    configLine={configIngredient}
                    keepEntity
                />
            </Grid>
            <Grid item xs={3}>
                <FormInput
                    endAdornment={getAdornmentFromUnit(subEntity?.ingredient?.unit)}
                    entity={subEntity}
                    updateEntity={updateSubEntity}
                    configLine={configQuantity}
                />
            </Grid>
        </Grid>
    );
};

export default SubformRecipeIngredient;
