/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { IconButton, Grid } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { FormEntity, SubFormType, FormElementType, ImageConverter, CustomLoader } from 'm6BoBuilder';
import { getObjectProperty } from 'm6BoBuilder/utils/getObjectProperty';
import { FormikValues } from 'formik';
import Recipe from 'config/resources/Recipe';
import { EventRecipeRecipeProperties } from 'config/resources/EventRecipeRecipe';
import { useGetRecipe } from 'services/useQueries';

const configRecipe: FormElementType = {
    label: 'Recette',
    property: EventRecipeRecipeProperties.recipe,
    options: {
        resource: Recipe.resourcePath,
    },
};

const SubformEventRecipeRecipe = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [id, setId] = useState();
    const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            weight: null,
            recipe: null,
            position: null,
        },
    );

    const { isFetching, data: recipeData } = useGetRecipe(id);

    useEffect(() => {
        recipeSelected();
    }, []);

    const recipeSelected = () => {
        if (entity[configLine.property][position] !== undefined) {
            const { recipe = null } = entity[configLine.property][position];

            if (null === recipe || recipe === undefined) {
                return;
            }
            const { id } = typeof recipe === 'string' ? { id: recipe.split('/').slice(-1)[0] } : recipe;
            if (null === id || id === undefined) {
                return;
            }
            setId(id);
        }
    };

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        entity[configLine.property][position] = updatedSubEntity;
        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);

        recipeSelected();
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={1} sx={{ marginTop: '20px' }}>
                {position + 1}
            </Grid>
            <Grid item xs={2}>
                {!isFetching && recipeData
                    ? getObjectProperty(recipeData, 'picture', (ImageConverter.display as any) ?? null)
                    : ''}
                {isFetching && (
                    <Grid container justifyContent="center" alignItems="center">
                        <CustomLoader width={50} height={50} style={{ margin: 0 }} />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={6}>
                <FormEntity
                    entity={subEntity}
                    defaultValue={recipeData}
                    updateEntity={updateSubEntity}
                    configLine={configRecipe}
                />
            </Grid>
            <Grid item xs={1} sx={{ marginTop: '20px' }}>
                {recipeData ? `${recipeData.rating}/5` : ''}
            </Grid>
            <Grid item xs={1} sx={{ marginTop: '10px' }}>
                {recipeData && (
                    <IconButton
                        aria-label="open"
                        color="primary"
                        size="small"
                        href={`${process.env.REACT_APP_WWW_URL}/mes-recettes/recette?id=${recipeData.id}`}
                        target="_blank"
                    >
                        <OpenInNew />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};

export default SubformEventRecipeRecipe;
