/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUserService } from './State/User/user.selector';
import { useApiErrorManager } from 'm6BoBuilder/hooks/useApiErrorManager';
//import { format } from 'date-fns';
//import { fr } from 'date-fns/locale';
//import Cookies from 'universal-cookie';

// MODELS
import { /*MutationUser,*/ PropsLogin } from 'models/User';
import {
    /*ingredientApi, menuApi, recipeApi,*/ resourceApi,
    resourceCBApi,
    userApi /*, userCBApi, userYCApi*/,
} from './routes';
import { AxiosError } from 'axios';
import { flashMessage, flashType } from 'm6BoBuilder/components/Flash';
//import { MutationMenu } from 'models/Menu';
import { UpdateResource } from '../models/Resources';

export const queryKeys = {
    getMe: ['getMe'],
    getUser: ['getUser'],
    getEntities: ['getEntities'],
    getEntity: ['getEntity'],
    getResources: ['getResources'],
    getResource: ['getResource'],
};

// QUERIES

// ENTITIES //
export const useGetAllEntities = (resourcePath: string, filters?: Record<string, any>) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getEntities, resourcePath, filters],
        async () => {
            const response = await resourceApi.getAll(resourcePath, filters);
            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, `Nous n'avons pas réussi à récupérer la liste ${resourcePath}`);
            },
        },
    );
};

export const useGetEntitiy = (resourcePath: string, resourceId: number | string, params?: Record<string, any>) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getEntity, resourcePath, resourceId],
        async () => {
            return await resourceApi.getById(resourcePath, resourceId, params);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, `Nous n'avons pas réussi à récupérer l'entité ${resourcePath}`);
            },
        },
    );
};

// RESOURCES //
export const useGetAllResources = (resourcePath: string, filters?: Record<string, any>, service = 'ck') => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getResources, resourcePath, filters],
        async () => {
            let response;
            if (service === 'cb') {
                response = await resourceCBApi.getAll(resourcePath, filters);
            } else {
                response = await resourceApi.getAll(resourcePath, filters);
            }
            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, `Nous n'avons pas réussi à récupérer la liste ${resourcePath}`);
                flashMessage(
                    `Nous n'avons pas réussi à récupérer la liste ${resourcePath}. \n${error}`,
                    flashType.ERROR,
                );
            },
        },
    );
};

export const useGetResource = (
    resourcePath: string,
    resourceId?: number | string,
    params?: Record<string, any>,
    queryOptions: any = null,
) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getResource, resourcePath, resourceId],
        async () => {
            if (!resourceId) {
                return;
            }
            return await resourceApi.getById(resourcePath, resourceId, params);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, `Nous n'avons pas réussi à récupérer l'entité ${resourcePath}`);
            },
            enabled: !!resourceId,
            ...queryOptions,
        },
    );
};

export const useGetCBResource = (
    resourcePath: string,
    resourceId?: number | string,
    params?: Record<string, any>,
    queryOptions: any = null,
) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getResource, resourcePath, resourceId],
        async () => {
            if (!resourceId) {
                return;
            }
            return await resourceCBApi.getById(resourcePath, resourceId, params);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, `Nous n'avons pas réussi à récupérer l'entité ${resourcePath}`);
            },
            ...queryOptions,
        },
    );
};

// USER //

export const useGetMe = () => {
    const { setUser } = useUserService();
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();
    void queryClient.invalidateQueries([queryKeys.getMe]);
    return useQuery(
        queryKeys.getMe,
        async () => {
            const me = await userApi.me();
            return setUser(me);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, 'Une erreur est survenue lors de la requete.');
            },
        },
    );
};

// MUTATIONS

// RESOURCES //
export const useCreateResource = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            resourcePath,
            data,
        }: {
            resourcePath: string;
            data: UpdateResource | any;
            callback?: CallableFunction;
        }) => await resourceApi.create(resourcePath, data),
        {
            onSuccess: (data, { resourcePath }) => {
                flashMessage(`L'élément a bien été créé.`, flashType.SUCCESS);
                void queryClient.invalidateQueries([...queryKeys.getResources, resourcePath]);
            },
            onSettled: async (data, error, { callback }, context) => {
                if (callback) {
                    await callback(data);
                }
            },
            onError: (error: AxiosError) => {
                void sendApiErrorManager(
                    error,
                    `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
                );
                flashMessage(
                    `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
                    flashType.ERROR,
                );
            },
        },
    );
};

export const useUpdateResourceById = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            resourcePath,
            resourceId,
            data,
        }: {
            resourcePath: string;
            resourceId: number | string;
            data: UpdateResource | any;
            callback?: CallableFunction;
        }) => await resourceApi.update(resourcePath, resourceId as number, data),
        {
            onSuccess: async (data, { resourcePath, resourceId }) => {
                flashMessage(`L'élément #${resourceId} a bien été mis à jour.`, flashType.SUCCESS);
                void queryClient.invalidateQueries([...queryKeys.getResources, resourcePath]);
            },
            onSettled: async (data, error, { callback }, context) => {
                if (callback) {
                    await callback(data);
                }
            },
            onError: (error: AxiosError) => {
                void sendApiErrorManager(
                    error,
                    `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
                );
                flashMessage(
                    `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
                    flashType.ERROR,
                );
            },
        },
    );
};

export const useDeleteResourceById = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();
    return useMutation(
        async ({ resourcePath, resourceId }: { resourcePath: string; resourceId: number | string }) =>
            await resourceApi.delete(resourcePath, resourceId as number),
        {
            onSuccess: (data, { resourcePath, resourceId }) => {
                flashMessage(`L'élément #${resourceId} a bien été supprimée.`, flashType.SUCCESS);
                void queryClient.invalidateQueries([resourcePath]);
            },
            onError: (error: AxiosError) => {
                void sendApiErrorManager(
                    error,
                    `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
                );
            },
        },
    );
};

// USER //
export const useLogin = () => {
    const { setUser, setToken, setRefreshToken, setRememberMe, setIsAuth } = useUserService();
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();
    void queryClient.invalidateQueries([queryKeys.getMe]);

    return useMutation((credential: PropsLogin) => userApi.login(credential), {
        onSuccess: (data, credential) => {
            setUser(data.user);
            setToken(data.token);
            setRefreshToken(data.refresh_token);
            setIsAuth(true);
            queryClient.invalidateQueries(queryKeys.getUser);
            setRememberMe({
                email: credential.username,
                password: credential.password,
            });
        },
        onError: (error) => {
            void sendApiErrorManager(error, "Erreur lors de l'authentification");
        },
    });
};
