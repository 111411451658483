import Recipe from './resources/Recipe';
import RecipeCategory from './resources/RecipeCategory';
import RecipeLevel from './resources/RecipeLevel';
import Ingredient from './resources/Ingredient';
import IngredientCategory from './resources/IngredientCategory';
import DietPlan from './resources/DietPlan';
import DietType from './resources/DietType';
import CommentRecipe from './resources/CommentRecipe';
import EventRecipe from './resources/EventRecipe';
import ArticleResource from './resources/Article';
import PrivateArticle from './resources/PrivateArticle';
import User from './resources/User';
import PromoCode from './resources/PromoCode';
import PromotionalCommunication from './resources/PromotionalCommunication';
import PrivatePromoBlock from './resources/PrivatePromoBlock';
import RecipeAutoPromo from './resources/RecipeAutoPromo';
import PrivatePromoPopin from './resources/PrivatePromoPopin';
import Season from './resources/Season';
import PromotionalMedia from './resources/PromotionalMedia';
import { ROLES } from '../constants';

import {
    IconShoppingCartDiscount,
    IconHome,
    IconNotebook,
    IconSalt,
    IconSoup,
    IconUsers,
    IconNews,
    IconPaperclip,
    IconSettings,
    IconUser,
    IconSettingsAutomation,
    IconFileSettings,
} from '@tabler/icons';
import { NavigationType } from 'm6BoBuilder';
import PromoCodeGroup from './resources/PromoCodeGroup';
import PromotionalNewsletter from './resources/PromotionalNewsletter';
import PrivateFooterBanner from './resources/PrivateFooterBanner';
import ArticleCategory from './resources/ArticleCategory';
import PrivateArticleCategory from './resources/PrivateArticleCategory';
import ArticleAuthor from './resources/ArticleAuthor';
import Review from './resources/Review';
import Parameter from './resources/Parameter';
import RecipePublicCategory from './resources/RecipePublicCategory';
import BodyParts from './resources/BodyParts';
import PublicPromoPopin from './resources/PublicPromoPopin';
import Feature from './resources/Feature';
import Objective from './resources/Objective';
import Tag from './resources/Tag';

const navigation: NavigationType = [
    {
        title: 'Accueil',
        link: '/',
        icon: IconHome,
        accessControl: [ROLES.ROLE_ADMIN],
    },
    {
        title: 'Ingrédients',
        icon: IconSalt,
        accessControl: [ROLES.ROLE_ADMIN],
        subnav: [
            {
                title: 'Tous les ingrédients',
                link: `/${Ingredient.resourcePath}`,
                singular: 'un ingrédient',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: "Catégories d'ingrédients",
                link: `/${IngredientCategory.resourcePath}`,
                singular: "une catégorie d'ingrédients",
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Régimes alimentaires',
                link: `/${DietPlan.resourcePath}`,
                singular: 'un régime alimentaire',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Tags',
                link: `/${Tag.resourcePath}`,
                singular: 'un tag',
                accessControl: [ROLES.ROLE_ADMIN],
            },
        ],
    },
    {
        title: 'Recettes',
        icon: IconSoup,
        accessControl: [ROLES.ROLE_ADMIN],
        subnav: [
            {
                title: 'Toutes les Recettes',
                link: `/${Recipe.resourcePath}`,
                singular: 'une recette',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Catégories de recettes',
                link: `/${RecipeCategory.resourcePath}`,
                singular: 'une catégorie',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Niveaux de recettes',
                link: `/${RecipeLevel.resourcePath}`,
                singular: 'un niveau',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Saisons de recettes',
                link: `/${Season.resourcePath}`,
                singular: 'une saison',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Commentaires',
                link: `/${CommentRecipe.resourcePath}`,
                singular: 'un commentaire',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Catégories de recettes publique',
                link: `/${RecipePublicCategory.resourcePath}`,
                singular: 'une catégorie',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Tags',
                link: `/${Tag.resourcePath}`,
                singular: 'un tag',
                accessControl: [ROLES.ROLE_ADMIN],
            },
        ],
    },
    {
        title: 'Menus',
        icon: IconNotebook,
        accessControl: [ROLES.ROLE_ADMIN],
        subnav: [
            {
                title: 'Planning des menus',
                link: `/menus-planning`,
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Objectifs',
                link: `/${Objective.resourcePath}`,
                singular: 'un objectif',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Types de menus',
                link: `/${DietType.resourcePath}`,
                singular: 'un type de menu',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Événements',
                link: `/${EventRecipe.resourcePath}`,
                singular: 'un événement',
                accessControl: [ROLES.ROLE_ADMIN],
            },
        ],
    },
    {
        title: 'Articles',
        icon: IconNews,
        accessControl: [ROLES.ROLE_ADMIN],
        subnav: [
            {
                title: 'Articles',
                link: `/${ArticleResource.resourcePath}`,
                singular: 'un article',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Articles privés',
                link: `/${PrivateArticle.resourcePath}`,
                singular: 'un article',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: "Catégories d'articles",
                link: `/${ArticleCategory.resourcePath}`,
                singular: 'une categorie',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: "Catégories d'articles privés",
                link: `/${PrivateArticleCategory.resourcePath}`,
                singular: 'une categorie',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: "Auteurs d'articles",
                link: `/${ArticleAuthor.resourcePath}`,
                singular: 'un auteur',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Article Newsletter',
                link: `/${PromotionalNewsletter.resourcePath}`,
                singular: 'une newsletter',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Medias promotionnels',
                link: `/${PromotionalMedia.resourcePath}`,
                singular: 'un media promo',
                accessControl: [ROLES.ROLE_ADMIN],
            },
        ],
    },
    {
        title: 'Promos',
        icon: IconShoppingCartDiscount,
        accessControl: [ROLES.ROLE_ADMIN],
        subnav: [
            {
                title: 'Codes promos',
                link: `/${PromoCode.resourcePath}`,
                singular: 'un code promo',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Codes promo en masse',
                singular: 'un groupe de code promos',
                link: `/${PromoCodeGroup.resourcePath}`,
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Bandeaux promo',
                link: `/${PromotionalCommunication.resourcePath}`,
                singular: 'un bandeau promo',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Bannières footer (espace privé)',
                link: `/${PrivateFooterBanner.resourcePath}`,
                singular: 'une bannière',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Block promo privé',
                link: `/${PrivatePromoBlock.resourcePath}`,
                singular: 'un block promo',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Auto promo (recette)',
                link: `/${RecipeAutoPromo.resourcePath}`,
                singular: 'une auto promo',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Popin promo privé',
                link: `/${PrivatePromoPopin.resourcePath}`,
                singular: 'une popin promo',
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Popin promo publique',
                link: `/${PublicPromoPopin.resourcePath}`,
                singular: 'une popin promo',
                accessControl: [ROLES.ROLE_ADMIN],
            },
        ],
    },
    {
        title: 'Témoignages',
        link: `/${Review.resourcePath}`,
        icon: IconUser,
        singular: 'un témoignage',
        accessControl: [ROLES.ROLE_ADMIN],
    },
    {
        title: 'Utilisateurs',
        link: `/${User.resourcePath}`,
        icon: IconUsers,
        singular: 'un utilisateur',
        accessControl: [ROLES.ROLE_ADMIN],
    },
    {
        title: 'Parametres',
        icon: IconFileSettings,
        accessControl: [ROLES.ROLE_ADMIN],
        subnav: [
            {
                title: 'Parametres',
                link: `/${Parameter.resourcePath}`,
                icon: IconSettings,
                accessControl: [ROLES.ROLE_ADMIN],
            },
            {
                title: 'Features',
                link: `/${Feature.resourcePath}`,
                singular: 'une fonctionnalité',
                icon: IconSettingsAutomation,
                accessControl: [ROLES.ROLE_ADMIN],
            },
        ],
    },
    {
        title: 'Annexes',
        icon: IconPaperclip,
        accessControl: [ROLES.ROLE_ADMIN],
        subnav: [
            {
                title: 'Parties du corps',
                link: `/${BodyParts.resourcePath}`,
                accessControl: [ROLES.ROLE_ADMIN],
            },
        ],
    },
];

export default navigation;
