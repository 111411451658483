/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectRenderType } from '../../types/forms/ConfigFormType';
import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    SelectChangeEvent,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';
import { isArray } from 'lodash';

const FormSelect = ({
    entity,
    defaultValue = '',
    updateEntity,
    error,
    configLine,
    fieldSize = 'medium',
    fieldWidth,
}: FormElementPropsType): JSX.Element => {
    const [selectedValue, setSelectedValue] = useState<string | string[]>(
        entity.hasOwnProperty(configLine.property) ? entity[configLine.property] : defaultValue,
    );

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        //step required, trigger useStates
        const updatedEntity = { ...entity, [configLine.property]: isNaN(Number(value)) ? value : Number(value) };
        updateEntity(updatedEntity, configLine.property);
        setSelectedValue(value);
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        //step required, trigger useStates
        const updatedEntity = { ...entity, [configLine.property]: isNaN(Number(value)) ? value : Number(value) };
        updateEntity(updatedEntity, configLine.property);
        setSelectedValue(value);
    };

    const handleSelectMultipleChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value;
        //step required, trigger useStates
        const updatedEntity = { ...entity, [configLine.property]: isArray(value) ? value : [value] };
        updateEntity(updatedEntity, configLine.property);
        setSelectedValue(value);
    };

    useEffect(() => {
        // Reset value
        if (entity[configLine.property] === undefined) {
            setSelectedValue(configLine.options?.multiple ? [] : '');
        } else if (entity[configLine.property] !== undefined) {
            setSelectedValue(entity[configLine.property]);
        }
    }, [entity]);

    switch (configLine.options?.render) {
        case SelectRenderType.radio:
            return (
                <FormControl size={fieldSize} component="fieldset" error={error}>
                    <FormLabel component="legend">{configLine.label}</FormLabel>
                    <RadioGroup
                        aria-label={configLine.label}
                        name={configLine.property}
                        value={selectedValue as string}
                        onChange={handleRadioChange}
                    >
                        {configLine.options?.choices?.map(
                            (element: { value: string | number | boolean; label: string }): JSX.Element => (
                                <FormControlLabel
                                    key={element.value.toString()}
                                    value={`${element.value}`}
                                    control={<Radio />}
                                    label={element.label}
                                />
                            ),
                        )}
                    </RadioGroup>
                </FormControl>
            );
        case SelectRenderType.select:
            if (configLine.options?.multiple) {
                return (
                    <FormControl size={fieldSize} sx={{ width: '100%', mb: 1 }} error={error}>
                        <InputLabel>{configLine.label}</InputLabel>
                        <Select
                            name={configLine.label}
                            label={configLine.label}
                            multiple
                            value={
                                typeof selectedValue === 'string'
                                    ? selectedValue.split(',').filter((item: string) => item.trim().length > 0)
                                    : selectedValue
                            }
                            onChange={handleSelectMultipleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected: any) => {
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value: string) => {
                                            let l: string | null = null;

                                            for (const p in configLine.options?.choices) {
                                                if (configLine.options?.choices[p].value == value) {
                                                    l = configLine.options?.choices[p].label;

                                                    break;
                                                }
                                            }

                                            return <Chip key={value} label={l || value} />;
                                        })}
                                    </Box>
                                );
                            }}
                            style={{ width: fieldWidth }}
                            // MenuProps={MenuProps}
                        >
                            {configLine.options?.choices?.map(
                                (element: { value: string | number | boolean; label: string }): JSX.Element => (
                                    <MenuItem key={element.value.toString()} value={`${element.value}`}>
                                        {element.label}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                );
            }
            return (
                <FormControl size={fieldSize} sx={{ minWidth: '20%', mb: 1 }} error={error}>
                    <InputLabel>{configLine.label}</InputLabel>
                    <Select
                        name={configLine.label}
                        label={configLine.label}
                        value={entity[configLine.property] ?? ''}
                        onChange={handleSelectChange}
                        inputProps={{
                            name: configLine.property,
                            id: configLine.property,
                        }}
                        style={{ width: fieldWidth }}
                    >
                        <MenuItem>
                            <em>Tout</em>
                        </MenuItem>
                        {configLine.options?.choices?.map(
                            (element: { value: string | number | boolean; label: string }): JSX.Element => (
                                <MenuItem key={element.value.toString()} value={`${element.value}`}>
                                    {element.label}
                                </MenuItem>
                            ),
                        )}
                    </Select>
                </FormControl>
            );
        default:
            return <>Unknow input type (check if managed by FormSelect Component).</>;
    }
};
export default FormSelect;
