import { useState, MouseEvent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Button, Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconFilePencil, IconTrash, IconSend } from '@tabler/icons';
import { Save } from '@mui/icons-material';
import * as yup from 'yup';

import inputTypes from 'm6BoBuilder/types/forms/InputTypes';
import { ConfigFormType, FormElementType, InputType } from 'm6BoBuilder/types/forms/ConfigFormType';
import { EditFormType } from 'm6BoBuilder/types/forms/EditFormType';
import { CustomActionType } from 'm6BoBuilder/types/ActionsType';

import DeleteModal from 'm6BoBuilder/components/DeleteModal';
import { CustomLoader, ResourceEditor } from 'm6BoBuilder';
import CommentRecipe from 'config/resources/CommentRecipe';
import { useCreateResource, useDeleteResourceById, useUpdateResourceById } from 'm6BoBuilder/services/useQueries';

const ErrorLabel = styled(Typography)(({ theme }) => ({
    marginTop: 5,
    color: theme.palette.error.main,
}));

const BtnsContainer = styled(Box)(() => ({
    '& > *:not(:last-child)': {
        marginRight: 10,
    },
}));

const CommentAnswerProperties = {
    id: 'id',
    text: 'text',
    parent: 'parent',
};

type CommentAnswerType = {
    id?: string;
    text?: string;
    parent?: string;
};

const CommentAnswerValidationSchema = yup.object({
    [CommentAnswerProperties.text]: yup.string().required('Ce champ est obligatoire'),
});

const CommentAnswerConfigForm: ConfigFormType = {
    label: 'Commentaire',
    listInputs: [
        {
            label: 'Réponse',
            property: CommentAnswerProperties.text,
            options: {
                textArea: true,
            },
        },
    ],
};

const CommentRecipeForm = ({
    entity,
    config,
    resourceType,
    errors,
    actions,
    handleEntityUpdate,
    noAction,
    inputSize,
}: EditFormType): JSX.Element => {
    //const parent = `/comment_recipes/${entity.id}`;
    const { comments } = entity;
    const comment: CommentAnswerType | undefined =
        comments?.length > 0 ? (comments[0] as CommentAnswerType) : undefined;

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModalVisible, setDeleteModal] = useState(false);
    const [isCommentAnswerVisible, showCommentAnswerForm] = useState(comment ? true : false);
    const [commentAnswer, setCommentAnswer] = useState(comment);

    const { isLoading: isCreateLoading, mutateAsync: createCommentAnswerMutateAsync } = useCreateResource();
    const { isLoading: isUpdateLoading, mutateAsync: updateCommentAnswerMutateAsync } = useUpdateResourceById();
    const { mutateAsync: deleteMutateAsync } = useDeleteResourceById();

    const navigate = useNavigate();

    const showDeleteModal = () => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event?.stopPropagation();
        setDeleteModal(true);
    };

    const showCommentAnswer = () => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event?.stopPropagation();
        showCommentAnswerForm(true);
    };

    const sendAnswer = () => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event?.stopPropagation();
        saveCommentAnswer();
    };

    const handleDelete = async () => {
        if (entity && entity.id) {
            await deleteMutateAsync({ resourcePath: resourceType, resourceId: entity.id });
            navigate(`/${resourceType}`);
            setDeleteModal(false);
        }
    };

    const handleResourceValue = (data: CommentAnswerType) => {
        setCommentAnswer(data);
    };

    const saveCommentAnswer = async () => {
        const answer = { ...commentAnswer, parent: entity['@id'] };
        if (commentAnswer && commentAnswer.id) {
            await updateCommentAnswerMutateAsync({
                resourcePath: resourceType,
                resourceId: commentAnswer.id,
                data: { ...answer },
            });
        } else {
            await createCommentAnswerMutateAsync({ resourcePath: resourceType, data: answer });
        }
        //navigate(0);
    };

    useEffect((): void => {
        setIsLoading(isCreateLoading || isUpdateLoading);
    }, [isCreateLoading, isUpdateLoading]);

    return (
        <>
            <Grid container spacing={3} justifyContent={'start'}>
                {config.listInputs.map((configLine: FormElementType, index: number) => {
                    const FormElement = inputTypes[configLine.type ?? InputType.text];
                    const onError = errors[configLine.property];
                    return (
                        <Grid
                            item
                            xs={inputSize}
                            md={inputSize}
                            lg={configLine.gridItemSize ? configLine.gridItemSize : inputSize}
                            key={index}
                            sx={{ position: 'relative' }}
                        >
                            <FormElement
                                error={onError}
                                entity={entity}
                                updateEntity={handleEntityUpdate}
                                configLine={configLine}
                            />
                            {onError && <ErrorLabel variant={'subtitle2'}>{errors[configLine.property]}</ErrorLabel>}
                        </Grid>
                    );
                })}

                {!(noAction && noAction === true) && (
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                            <BtnsContainer>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    startIcon={<Save />}
                                    type="submit"
                                >
                                    Enregistrer
                                </Button>
                                {actions?.customs && (
                                    <>
                                        {actions.customs.map((customAction: CustomActionType, index) => {
                                            if (customAction.showInForm === false) {
                                                return null;
                                            }
                                            const Action = customAction.component;
                                            return (
                                                <Action
                                                    key={`action_${index}`}
                                                    config={customAction.config || {}}
                                                    id={entity.id}
                                                />
                                            );
                                        })}
                                    </>
                                )}
                                {actions?.delete && entity.id > 0 && (
                                    <Button
                                        color="error"
                                        size="large"
                                        variant="contained"
                                        startIcon={<IconTrash />}
                                        onClick={showDeleteModal()}
                                    >
                                        Supprimer
                                    </Button>
                                )}
                                {!isCommentAnswerVisible && !comment && (
                                    <Button
                                        color="secondary"
                                        size="large"
                                        variant="contained"
                                        startIcon={<IconFilePencil />}
                                        onClick={showCommentAnswer()}
                                    >
                                        Répondre
                                    </Button>
                                )}
                            </BtnsContainer>
                        </Stack>
                    </Grid>
                )}
            </Grid>
            {isLoading && (
                <>
                    <Grid container justifyContent="center" alignItems="center">
                        <CustomLoader width={50} height={50} style={{ margin: 0 }} />
                    </Grid>
                </>
            )}
            {!isLoading && (isCommentAnswerVisible || comment) && (
                <>
                    <Grid>
                        <Typography component="p" sx={{ mt: 4, mb: 4 }}>
                            Réponse au commentaire
                        </Typography>
                        <ResourceEditor
                            id={comment?.id ?? ''}
                            resourceType={resourceType}
                            resource={commentAnswer}
                            config={{
                                ...CommentAnswerConfigForm,
                            }}
                            actions={actions}
                            validationSchema={CommentAnswerValidationSchema}
                            placeHolder={CommentRecipe.placeHolder}
                            noAction={true}
                            setResourceValue={handleResourceValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                            <BtnsContainer>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    startIcon={<IconSend />}
                                    type="button"
                                    onClick={sendAnswer()}
                                >
                                    Répondre
                                </Button>
                            </BtnsContainer>
                        </Stack>
                    </Grid>
                </>
            )}

            <DeleteModal
                modalOpen={isDeleteModalVisible}
                closeModal={() => setDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default CommentRecipeForm;
