import { Box } from '@mui/material';
import { Puff } from 'react-loader-spinner';
import { useTheme } from '@mui/material';

type CustomLoaderProps = {
    height?: number;
    width?: number;
    style?: Record<string, any>;
};

const CustomLoader = ({ height = 100, width = 100, style = { margin: 20 } }: CustomLoaderProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Box style={style}>
            <Puff color={theme.palette.secondary[200]} height={height} width={width} />
        </Box>
    );
};

export default CustomLoader;
