import { useState, /*useContext,*/ MouseEvent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Button, Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconTrash } from '@tabler/icons';
import { Save } from '@mui/icons-material';

import inputTypes from '../../types/forms/InputTypes';
import { FormElementType, InputType } from '../../types/forms/ConfigFormType';
import { EditFormType } from '../../types/forms/EditFormType';
import { CustomActionType } from '../../types/ActionsType';

//import ApiService from '../../services/ApiService';
//import { ApiContext } from '../../AppBoBuilder';
import { flashMessage } from '../Flash';
import DeleteModal from '../DeleteModal';
import { useDeleteResourceById } from 'm6BoBuilder/services/useQueries';

const ErrorLabel = styled(Typography)(({ theme }) => ({
    marginTop: 5,
    color: theme.palette.error.main,
}));

const BtnsContainer = styled(Box)(() => ({
    '& > *:not(:last-child)': {
        marginRight: 10,
    },
}));

const DefaultForm = ({
    entity,
    config,
    resourceType,
    errors,
    actions,
    handleEntityUpdate,
    noAction,
    inputSize,
}: EditFormType): JSX.Element => {
    const [isDeleteModalVisible, setDeleteModal] = useState(false);
    //const apiService: ApiService = useContext(ApiContext);
    const navigate = useNavigate();

    const { mutateAsync: deleteResourceMutateAsync, isSuccess: isSuccessDeleteResource } = useDeleteResourceById();

    const showDeleteModal = () => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event?.stopPropagation();
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        //await apiService.remove(resourceType, entity.id);
        await deleteResourceMutateAsync({ resourcePath: resourceType, resourceId: entity.id });
    };

    useEffect(() => {
        if (isSuccessDeleteResource) {
            flashMessage(`${config.label} #${entity.id} a bien été supprimée`);
            navigate(`/${resourceType}`);
            setDeleteModal(false);
        }
    }, [isSuccessDeleteResource]);

    return (
        <>
            <Grid container spacing={3} justifyContent={'start'}>
                {config.listInputs.map((configLine: FormElementType, index: number) => {
                    const FormElement = inputTypes[configLine.type ?? InputType.text];
                    const onError = errors[configLine.property];
                    return (
                        <Grid
                            item
                            xs={inputSize}
                            md={inputSize}
                            lg={configLine.gridItemSize ? configLine.gridItemSize : inputSize}
                            key={index}
                            sx={{ position: 'relative' }}
                        >
                            <FormElement
                                error={onError}
                                entity={entity}
                                defaultValue={entity[configLine.property]}
                                updateEntity={handleEntityUpdate}
                                configLine={configLine}
                            />
                            {onError && <ErrorLabel variant={'subtitle2'}>{errors[configLine.property]}</ErrorLabel>}
                        </Grid>
                    );
                })}

                {!(noAction && noAction === true) && (
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                            <BtnsContainer>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    startIcon={<Save />}
                                    type="submit"
                                >
                                    Enregistrer
                                </Button>
                                {actions?.customs && (
                                    <>
                                        {actions.customs.map((customAction: CustomActionType, index) => {
                                            if (customAction.showInForm === false) {
                                                return null;
                                            }
                                            const Action = customAction.component;
                                            return (
                                                <Action
                                                    key={`action_${index}`}
                                                    config={customAction.config || {}}
                                                    id={entity.id}
                                                />
                                            );
                                        })}
                                    </>
                                )}
                                {actions?.delete && entity.id > 0 && (
                                    <Button
                                        color="error"
                                        size="large"
                                        variant="contained"
                                        startIcon={<IconTrash />}
                                        onClick={showDeleteModal()}
                                    >
                                        Supprimer
                                    </Button>
                                )}
                            </BtnsContainer>
                        </Stack>
                    </Grid>
                )}
            </Grid>

            <DeleteModal
                modalOpen={isDeleteModalVisible}
                closeModal={() => setDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default DefaultForm;
