/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEvent } from 'react';
import { TableCell, TableRow, IconButton, Tooltip, /*Box,*/ Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconTrash } from '@tabler/icons';

// Types
import { ConfigListType, TableListElementType } from '../../types/lists/ConfigListType';
import { ResourceType } from '../../types/ResourceType';
import { ActionType, CustomActionType } from '../../types/ActionsType';

import { getObjectProperty } from 'm6BoBuilder/utils/getObjectProperty';

type SelectableTableRowProps = {
    navigate?: boolean;
    onSelect: (checked: boolean, element: { id: string }) => void;
    element: { ['@id']: string; id: string; [key: string]: any };
    index: number;
    resource: ResourceType;
    actions?: ActionType[];
    theme: any;
    config: ConfigListType;
    checked: boolean;
    showDeleteModal: (id: string) => (event: MouseEvent<HTMLButtonElement>) => void;
};

export const SelectableTableRow = ({
    navigate,
    onSelect,
    element,
    index,
    resource,
    theme,
    config,
    showDeleteModal,
    actions,
    checked,
}: SelectableTableRowProps): JSX.Element => {
    const navigateTo = useNavigate();
    return (
        <TableRow
            key={element['@id']}
            onClick={() => {
                if (navigate !== false) {
                    navigateTo(`/${resource.resourcePath}/${element[resource.identifier ?? 'id']}`);
                }
            }}
            sx={[
                index % 2
                    ? { backgroundColor: theme.palette.background.default }
                    : { backgroundColor: theme.palette.action.hover },
                navigate !== false && {
                    cursor: 'pointer',
                    textDecoration: 'none',
                    '&:hover': {
                        backgroundColor:
                            theme.palette.mode === 'dark' ? theme.palette.primary[800] : theme.palette.secondary.light,
                    },
                },
                checked && {
                    backgroundColor:
                        theme.palette.mode === 'dark' ? theme.palette.primary[800] : theme.palette.secondary.light,
                },
            ]}
        >
            {config?.selectable && (
                <TableCell key={`sort-${element[resource.identifier ?? 'id']}`} align="left">
                    <Checkbox
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onSelect(!checked, element);
                        }}
                        checked={checked}
                    />
                </TableCell>
            )}
            {config.listProperties.map((configLine: TableListElementType) => (
                <TableCell key={configLine.property} align="left" sx={configLine.styles}>
                    {getObjectProperty(element, configLine.property, configLine.transform ?? null)}
                </TableCell>
            ))}
            {resource.actions && (
                <TableCell width={100} size="medium" align="left">
                    {resource.actions.delete && (
                        <Tooltip title="Supprimer">
                            <IconButton onClick={showDeleteModal(element[resource.identifier ?? 'id'])} color="error">
                                <IconTrash />
                            </IconButton>
                        </Tooltip>
                    )}
                    {resource.actions.customs && (
                        <>
                            {resource.actions.customs.map((customAction: CustomActionType, index) => {
                                const Action = customAction.component;
                                return (
                                    <Action
                                        key={`action_${index}`}
                                        config={customAction.config || {}}
                                        id={element[resource.identifier ?? 'id']}
                                        data={element}
                                    />
                                );
                            })}
                        </>
                    )}
                    {actions && (
                        <>
                            {actions.map((Action: ActionType, index) => {
                                return <Action key={index} config={{}} id={element[resource.identifier ?? 'id']} />;
                            })}
                        </>
                    )}
                </TableCell>
            )}
        </TableRow>
    );
};
