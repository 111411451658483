import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const TagProperties = {
    id: 'id',
    name: 'name',
    slug: 'slug',
    totalIngredients: 'totalIngredients',
    totalRecipes: 'totalRecipes',
};

const Tag: ResourceType = {
    resourcePath: 'bo-tags',
    placeHolder: {
        name: '',
    },

    validationSchema: yup.object({
        [TagProperties.name]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Tags',
        listInputs: [
            {
                label: 'Nom',
                property: TagProperties.name,
            },
        ],
    },

    configList: {
        label: 'Tags',
        listProperties: [
            {
                label: 'id',
                property: TagProperties.id,
                sortable: true,
            },
            {
                label: 'Nom',
                property: TagProperties.name,
                sortable: true,
            },
            {
                label: 'Key',
                property: TagProperties.slug,
                sortable: true,
            },
            {
                label: "Nombre d'ingrédients",
                property: TagProperties.totalIngredients,
                styles: {
                    width: '15%',
                },
            },
            {
                label: 'Nombre de recettes',
                property: TagProperties.totalRecipes,
                styles: {
                    width: '15%',
                },
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Tag',
                property: TagProperties.name,
                type: InputType.text,
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default Tag;
export { TagProperties };
