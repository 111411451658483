import { Stack, Typography } from '@mui/material';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';

const DietTypeBaseCell = (element: any, dietType: any): JSX.Element => {
    return (
        <>
            <Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <RestaurantMenuOutlinedIcon sx={{ fontSize: 16 }} />
                    <Stack direction="column" spacing={0.5} alignItems="left">
                        <Typography>{dietType.isDefault ? 'Base' : element}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default DietTypeBaseCell;
