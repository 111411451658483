/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ActionsType,
    ActionType,
    ActionTypeProps,
    CustomActionType,
    CustomLoader,
    DateConverter,
    flashMessage,
    flashType,
    MainCard,
    ResourceEditor,
    ScreenPropsType,
    ScreenType,
    UserType,
} from 'm6BoBuilder';
// Libs
import { MouseEvent, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Box, Button, CardContent, Grid, Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import { PersonOff, PersonRemove, Person, Save /*, Group*/ } from '@mui/icons-material';
import moment from 'moment';
import UserOffersCard from '../../components/UserOffersCard';
import User from '../../config/resources/User';
import UserLogCard from '../../components/UserLogCard';
import UserCK from 'config/resources/UserCK';
import UserCB from 'config/resources/UserCB';
import UserYG from 'config/resources/UserYG';
import { ObjectSchema } from 'yup';
import { RessourceMode } from 'm6BoBuilder/types/ResourceType';
import Cookies from 'universal-cookie';

// queries
import {
    useDeleteUser,
    useGetUser,
    useGetUserCB,
    //useGetUserYC,
    useImpersonate,
    useMergeUser,
    usePostUserCB,
    //usePostUserYC,
    useUnsubscribeUser,
    useUpdateUser,
    useUpdateUserCB,
    //useUpdateUserYC,
} from 'services/useQueries';

import InfosUser from './InfosUser';
import { User as UserModel } from '../../interfaces/user.interface';
import LockModal from './LockModal';
import DeleteModal from './DeleteModal';
import MergeModal from './MergeModal';
import UnsubscribeModal from './UnsubscribeModal';
import { useUserService } from 'm6BoBuilder/services/State/User/user.selector';

const BtnsContainer = styled(Box)(() => ({
    '&>button': {
        marginRight: 10,
        marginBottom: 10,
    },
}));

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const UserEditScreen: ScreenType = ({ resource }: ScreenPropsType): JSX.Element => {
    const config = resource.configForm;
    const navigate = useNavigate();
    const { user } = useUserService();

    const { id } = useParams<string>();
    // tabs
    const [tabValue, setTabValue] = useState(0);
    // errors
    const [errorsUser, setErrorsUser] = useState({});
    const [errorsUserCK, setErrorsUserCK] = useState({});
    // data
    const [userDataCB, setUserDataCB] = useState<UserType | null>(null);
    //const [userDataYC, setUserDataYC] = useState<UserType | null>(null);
    const [data, setData] = useState<UserModel | null>(null); // TODO à revoir
    // modal
    const [unsubModal, setUnsubModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [lockModal, setLockModal] = useState(false);
    const [mergeModal, setMergeModal] = useState(false);

    const [actions, setActions] = useState<ActionsType>({
        delete: false,
    });

    // Queries
    const { isFetching, isError, error, data: userData } = useGetUser(id);
    const { data: userCBData, refetch: refetchCBData } = useGetUserCB(id, { enabled: false });
    const { mutateAsync: mutateAsyncPostUserCB, isSuccess: isSuccessPostUserCB } = usePostUserCB();
    //const { data: userYCData, refetch: refetchYCData } = useGetUserYC(id, { enabled: false });
    //const { mutateAsync: mutateAsyncPostUserYC, isSuccess: isSuccessPostUserYC } = usePostUserYC();

    const { mutateAsync: updateUserMutateAsync, isSuccess: isSuccessUpdateUser, data: updatedUser } = useUpdateUser();
    const {
        mutateAsync: updateUserCBMutateAsync,
        isSuccess: isSuccessUpdateUserCB,
        data: updatedUserCB,
    } = useUpdateUserCB();
    /*const {
        mutateAsync: updateUserYCMutateAsync,
        isSuccess: isSuccessUpdateUserYC,
        data: updatedUserYC,
    } = useUpdateUserYC();*/
    const { mutateAsync: impersonateMutateAsync } = useImpersonate();
    const {
        mutateAsync: unsubcribeMutateAsync,
        isSuccess: isSuccessUnsubscribeUser,
        data: unsubcribeResponse,
    } = useUnsubscribeUser();
    const { mutateAsync: deleteUserMutateAsync, isSuccess: isSuccessDeleteUser } = useDeleteUser();
    const { mutateAsync: mergeUserMutateAsync, isSuccess: isSuccessMergeUser } = useMergeUser();

    if (id === undefined) {
        return <>This screen is only available for edition</>;
    }

    // ACTION BUTTONS
    const UserUnsub: ActionType = ({ id }: ActionTypeProps) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const initUserUnsubForm = (id: string | number) => (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event?.stopPropagation();
            setUnsubModal(true);
        };
        return (
            <>
                <Button
                    startIcon={<PersonOff />}
                    color="error"
                    size="large"
                    variant="contained"
                    onClick={initUserUnsubForm(id)}
                >
                    {`Résilier l'abonnement`}
                </Button>
            </>
        );
    };

    const UserDelete: ActionType = ({ id }: ActionTypeProps) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const initUserDeleteForm = (id: string | number) => (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event?.stopPropagation();
            setDeleteModal(true);
        };
        return (
            <>
                <Button
                    startIcon={<PersonRemove />}
                    color="error"
                    size="large"
                    variant="contained"
                    onClick={initUserDeleteForm(id)}
                >
                    {`Supprimer le compte`}
                </Button>
            </>
        );
    };

    /*const UserLock: ActionType = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const handleLockUser = () => (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event?.stopPropagation();
            setLockModal(true);
        };
        return (
            <>
                <Button
                    startIcon={userData?.locked ? <IconLockOff /> : <IconLock />}
                    color="error"
                    size="large"
                    variant="contained"
                    onClick={handleLockUser()}
                >
                    {`${userData?.locked ? 'Débloquer' : 'Bloquer'} le compte`}
                </Button>
            </>
        );
    };*/

    /*const UserMerge: ActionType = () => {
        const initUserMergeForm = () => (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event?.stopPropagation();
            setMergeModal(true);
        };
        return (
            <>
                <Button
                    startIcon={<Group />}
                    color="error"
                    size="large"
                    variant="contained"
                    onClick={initUserMergeForm()}
                >
                    {`Fusionner avec un autre compte`}
                </Button>
            </>
        );
    };*/

    const UserImpersonate: ActionType = ({ id }: ActionTypeProps) => {
        const impersonateUser = async () => {
            await impersonateMutateAsync(id as number).then((data) => {
                const cookies = new Cookies();
                const d = new Date();
                d.setDate(d.getDate() + 1);
                const cookiesOptions = { path: '/', domain: '.croq-kilos.com', maxAge: 24, expires: d };
                cookies.set('uid', data.uid, cookiesOptions);
                cookies.set('jwt ', data.token, cookiesOptions);
                cookies.set('reft ', data.refreshToken, cookiesOptions);
                cookies.set('impersonate ', true, cookiesOptions);
                window.open(`${process.env.REACT_APP_WWW_URL}`, '_blank');
            });
        };
        return (
            <>
                <Button
                    startIcon={<Person />}
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={impersonateUser}
                >
                    {`Se faire passer pour`}
                </Button>
            </>
        );
    };

    const customActions: any[] = [];

    if (id != user.id) {
        //customActions.push({ component: UserMerge });
        //customActions.push({ component: UserLock });
        customActions.push({ component: UserDelete });
    }

    // USE EFFECTS
    useEffect(() => {
        if (userData) {
            setData(userData);

            if (userData?.roles.includes('ROLE_CLIENT_CB') || userDataCB) {
                refetchCBData();
            }

            /*if (userData?.roles.includes('ROLE_CLIENT_YG') || userDataYC) {
                refetchYCData();
            }*/

            if (
                userData &&
                (userData.roles?.includes('ROLE_CLIENT_CK') ||
                    userData.roles?.includes('ROLE_CLIENT_CB') ||
                    userData.roles?.includes('ROLE_CLIENT_YG'))
            ) {
                customActions.push({ component: UserUnsub }, { component: UserImpersonate });
            }
        }

        setActions({
            delete: false,
            customs: customActions,
        });
    }, [userData]);

    useEffect(() => {
        if (isSuccessUpdateUser && updatedUser) {
            if (config.afterSubmit) {
                config.afterSubmit(updatedUser, RessourceMode.Edit);
            }

            if (UserCK.configForm.afterSubmit) {
                UserCK.configForm.afterSubmit?.(updatedUser, RessourceMode.Edit);
            }

            if (userData?.roles.includes('ROLE_CLIENT_CB') || userDataCB) {
                updateCBUser();
            }

            /*if (userData?.roles.includes('ROLE_CLIENT_YG') || userDataYC) {
                updateYCUser();
            }*/

            flashMessage(`${resource.resourcePath} mis(e) à jour`, flashType.SUCCESS);
            return;
        }
    }, [isSuccessUpdateUser, updatedUser]);

    useEffect(() => {
        if (isError && error) {
            const axiosError: AxiosError = error as AxiosError;
            flashMessage(axiosError.response?.data['hydra:description'] ?? axiosError.message, flashType.ERROR);
            navigate(0);
        }
    }, [isError, error]);

    useEffect(() => {
        if (isSuccessUnsubscribeUser && unsubcribeResponse) {
            setUnsubModal(false);
            flashMessage(
                `Compte résilié avec prise d'effet le ${DateConverter.display(unsubcribeResponse.effectiveDate)}`,
            );
            navigate(0);
        }
    }, [isSuccessUnsubscribeUser]);

    useEffect(() => {
        if (isSuccessDeleteUser) {
            navigate(`/${User.resourcePath}`);
        }
    }, [isSuccessDeleteUser]);

    useEffect(() => {
        if (isSuccessMergeUser) {
            navigate(0);
        }
    }, [isSuccessMergeUser]);

    //

    const updateCBUser = async () => {
        let response = null;
        if (userCBData) {
            response = await updateUserCBMutateAsync({ userId: id, data: userDataCB });
        } else {
            response = await mutateAsyncPostUserCB({
                ...(userDataCB || UserCB.placeHolder),
                id: parseInt(id),
                username: userData?.email,
            });
        }
        if ((isSuccessUpdateUserCB && updatedUserCB) || isSuccessPostUserCB) {
            if (UserCB.configForm.afterSubmit) {
                UserCB.configForm.afterSubmit?.(response, RessourceMode.Edit);
            }
        }
    };

    /*const updateYCUser = async () => {
        let response = null;
        if (userYCData) {
            response = await updateUserYCMutateAsync({ userId: id, data: userDataYC });
        } else {
            response = await mutateAsyncPostUserYC({
                ...(userDataYC || UserYG.placeHolder),
                id: parseInt(id),
                username: userData?.email,
            });
        }
        if ((isSuccessUpdateUserYC && updatedUserYC) || isSuccessPostUserYC) {
            if (UserYG.configForm.afterSubmit) {
                UserYG.configForm.afterSubmit?.(response, RessourceMode.Edit);
            }
        }
    };*/

    const validate = async (data: any, schema: ObjectSchema<any>, setErrors: (err: any) => void) => {
        const isValid = await schema.isValid(data);
        if (!isValid) {
            schema.validate(data, { abortEarly: false }).catch((err: any) => {
                const errors = err.inner.reduce((acc: any, error: any) => {
                    return {
                        ...acc,
                        [error.path]: error.message,
                    };
                }, {});
                setErrors(errors);
            });
            window.scrollTo(0, 0);
            flashMessage('Certains champs sont invalides', flashType.ERROR);
        }
        return isValid;
    };

    // HANDLERS

    const handleSubmit = async () => {
        const userDetailsValidation = resource.validationSchema(1);
        const isUserValid = await validate(data, userDetailsValidation, setErrorsUser);
        const isUserCKValid = await validate(data, UserCK.validationSchema, setErrorsUserCK);
        if (isUserCKValid && isUserValid) {
            let apiUser: any = data;
            if (apiUser !== null) {
                if (config.beforeSubmit) {
                    apiUser = config.beforeSubmit?.(apiUser, RessourceMode.Edit);
                }
                if (UserCK.configForm.beforeSubmit) {
                    apiUser = UserCK.configForm.beforeSubmit?.(apiUser, RessourceMode.Edit);
                }
                if (UserCB.configForm.beforeSubmit) {
                    apiUser = UserCB.configForm.beforeSubmit?.(apiUser, RessourceMode.Edit);
                }
                if (UserYG.configForm.beforeSubmit) {
                    apiUser = UserYG.configForm.beforeSubmit?.(apiUser, RessourceMode.Edit);
                }
            }

            for (const prop in apiUser) {
                if (typeof apiUser[prop] === 'object' && apiUser[prop] !== null) {
                    for (const innerProp in apiUser[prop]) {
                        if (apiUser[prop][innerProp] === null) {
                            delete apiUser[prop][innerProp];
                        }
                    }
                    if (Object.keys(apiUser[prop]).length === 0) {
                        delete apiUser[prop];
                    }
                } else if (apiUser[prop] === null) {
                    delete apiUser[prop];
                }
            }

            await updateUserMutateAsync({ userId: id, data: apiUser });
        }
    };

    const handleCKValues = (values: any) => {
        setData({ ...userData, ...values });
    };

    const handleCBValues = (values: any) => {
        setUserDataCB({ ...userDataCB, ...values });
    };

    /*const handleYCValues = (values: any) => {
        setUserDataYC({ ...userDataYC, ...values });
    };*/

    const handleSubmitUnsubscribeUser = async ({ userId, resiliationType, service }: any) => {
        await unsubcribeMutateAsync({
            userId,
            params: {
                resiliationType,
                service,
            },
        });
    };

    const handleSubmitDeleteUser = async ({ userId, deleteDate }: any) => {
        if (deleteDate) {
            const deleteAt = moment(deleteDate).format('YYYY-MM-DD');
            await updateUserMutateAsync({ userId, data: { deleteAt } });
            await navigate(`/${User.resourcePath}`);
        } else {
            await deleteUserMutateAsync(userId);
        }
    };

    const handleSubmitLockUser = async ({ userId }: any) => {
        await updateUserMutateAsync({ userId, data: { locked: !userData?.locked } });
    };

    const handleMergeUser = async ({ userId, userToMergeEmail }: any) => {
        if (userToMergeEmail !== null && userToMergeEmail !== '') {
            await mergeUserMutateAsync({ userId, userToMerge: userToMergeEmail });
        }
    };

    const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const tabs = [];
    tabs.push({
        index: 'profile',
        tab: <Tab label="Profile" {...a11yProps(tabs.length)} key="profile" />,
        content: (
            <ResourceEditor
                id={id}
                inputSize={6}
                resourceType={resource.resourcePath}
                config={config}
                validationSchema={resource.validationSchema}
                placeHolder={resource.placeHolder}
                defaultValues={resource.defaultValues}
                showHelpCta={false}
                resource={userData}
                setResourceValue={(data) => {
                    setData(data);
                }}
                noAction={true}
                errorsAll={errorsUser}
            />
        ),
    });
    if (userData?.roles.includes('ROLE_CLIENT_CK')) {
        tabs.push({
            index: 'croqkilos_infos',
            tab: <Tab label="Croqkilos" {...a11yProps(tabs.length)} key="croqkilos_infos" />,
            content: (
                <InfosUser
                    title="Données Croq'Kilos"
                    id={id}
                    resource={UserCK}
                    errors={errorsUserCK}
                    onUpdateValues={handleCKValues}
                />
            ),
        });
    }
    if (userData?.roles.includes('ROLE_CLIENT_CB')) {
        tabs.push({
            index: 'croqbody_infos',
            tab: <Tab label="CroqBody" {...a11yProps(tabs.length)} key="croqbody_infos" />,
            content: (
                <InfosUser
                    title="Données Croq'Body"
                    id={id}
                    resource={UserCB}
                    errors={errorsUser}
                    onUpdateValues={handleCBValues}
                />
            ),
        });
    }
    tabs.push({
        index: 'subscriptions',
        tab: <Tab label="Abonnements" {...a11yProps(tabs.length)} key="subscriptions" />,
        content: <UserOffersCard userId={id} />,
    });
    tabs.push({
        index: 'logs',
        tab: <Tab label="Logs" {...a11yProps(tabs.length)} />,
        content: <UserLogCard id={id} />,
    });

    return (
        <>
            {isFetching && (
                <Grid container justifyContent="center" alignItems="center" mt={4}>
                    <CustomLoader />
                </Grid>
            )}
            {!isFetching && (
                <MainCard>
                    <CardContent
                        sx={{
                            width: '100%',
                        }}
                    >
                        <Box sx={{ width: '100%' }} key="tabs_container">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="">
                                    {tabs.map((elt: any) => elt.tab)}
                                </Tabs>
                            </Box>
                            {tabs.map((elt: any, index: number) => {
                                return (
                                    <CustomTabPanel key={`tab_${index}`} value={tabValue} index={index}>
                                        {elt.content}
                                    </CustomTabPanel>
                                );
                            })}
                        </Box>

                        <Grid item xs={12}>
                            <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                                <BtnsContainer>
                                    <Button
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        startIcon={<Save />}
                                        onClick={handleSubmit}
                                    >
                                        Enregistrer
                                    </Button>
                                    {actions?.customs && (
                                        <>
                                            {actions.customs.map((customAction: CustomActionType, index) => {
                                                const Action = customAction.component;
                                                return (
                                                    <Action
                                                        key={`action_${index}`}
                                                        config={customAction.config || {}}
                                                        id={id}
                                                    />
                                                );
                                            })}
                                        </>
                                    )}
                                </BtnsContainer>
                            </Stack>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}

            {unsubModal && (
                <UnsubscribeModal
                    open={unsubModal}
                    user={userData as UserModel}
                    onClose={() => setUnsubModal(false)}
                    onSubmit={(data) => {
                        handleSubmitUnsubscribeUser(data);
                        setUnsubModal(false);
                    }}
                />
            )}
            {deleteModal && (
                <DeleteModal
                    open={deleteModal}
                    user={userData as UserModel}
                    onClose={() => setDeleteModal(false)}
                    onSubmit={(data) => {
                        handleSubmitDeleteUser(data);
                        setDeleteModal(false);
                    }}
                />
            )}
            {lockModal && (
                <LockModal
                    open={lockModal}
                    user={userData as UserModel}
                    onClose={() => setLockModal(false)}
                    onSubmit={(data) => {
                        handleSubmitLockUser(data);
                        setLockModal(false);
                    }}
                />
            )}
            {mergeModal && (
                <MergeModal
                    open={mergeModal}
                    user={userData as UserModel}
                    onClose={() => setMergeModal(false)}
                    onSubmit={(data) => {
                        handleMergeUser(data);
                        setMergeModal(false);
                    }}
                />
            )}
        </>
    );
};

export default UserEditScreen;
