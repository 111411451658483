/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResourceType, InputType, BooleanConverter, DateConverter, ImageConverter } from 'm6BoBuilder';
import * as yup from 'yup';

const ObjectiveProperties = {
    id: 'id',
    position: 'position',
    title: 'title',
    description: 'description',
    dietTypes: 'dietTypes',
    status: 'status',
    media: 'media',
    picto: 'picto',
    totalDietTypes: 'totalDietTypes',
    createdBy: 'createdBy',
    updatedBy: 'updatedBy',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
};

const Objective: ResourceType = {
    resourcePath: 'bo-objectives',
    placeHolder: {
        [ObjectiveProperties.id]: '',
        [ObjectiveProperties.title]: '',
        [ObjectiveProperties.description]: '',
        [ObjectiveProperties.position]: null,
        [ObjectiveProperties.media]: null,
        [ObjectiveProperties.picto]: null,
        [ObjectiveProperties.status]: false,
        [ObjectiveProperties.updatedAt]: 0,
    },
    validationSchema: yup.object({
        [ObjectiveProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Objectifs',
        listInputs: [
            {
                label: 'Titre',
                property: ObjectiveProperties.title,
            },
            {
                label: 'Description',
                property: ObjectiveProperties.description,
            },
            {
                label: 'Activé',
                property: ObjectiveProperties.status,
                type: InputType.boolean,
            },
            {
                label: 'Media',
                property: ObjectiveProperties.media,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Picto',
                property: ObjectiveProperties.picto,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
        ],
    },
    configList: {
        label: 'Objectifs',
        listProperties: [
            {
                label: '#',
                property: ObjectiveProperties.id,
                sortable: true,
                styles: {
                    width: '5%',
                },
            },
            {
                label: 'Media',
                property: ObjectiveProperties.media,
                transform: ImageConverter.display,
                styles: {
                    width: '15%',
                },
            },
            {
                label: 'Picto',
                property: ObjectiveProperties.picto,
                transform: ImageConverter.display,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Objectif',
                property: ObjectiveProperties.title,
                sortable: true,
            },
            {
                label: 'Description',
                property: ObjectiveProperties.description,
            },
            {
                label: 'Nombre de menu',
                property: ObjectiveProperties.totalDietTypes,
            },
            {
                label: 'Publié',
                property: ObjectiveProperties.status,
                transform: (element: number): JSX.Element =>
                    BooleanConverter.display(BooleanConverter.convert(element)),
                sortable: true,
            },
            {
                label: 'Date de modification',
                property: ObjectiveProperties.updatedAt,
                transform: DateConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export type ObjectiveType = {
    id?: number;
    position: number;
    title: string;
    description: string;
    dietTypes: any[];
    status: number;
    media: any;
    picto: any;
    publishedAtStart: Date;
    publishedAtEnd: Date;
    totalDietTypes: number;
};

export default Objective;
export { ObjectiveProperties };
