/* eslint-disable @typescript-eslint/no-explicit-any */

import { InputType, ResourceType, BooleanConverter, ActionType, ActionTypeProps } from 'm6BoBuilder';

import * as yup from 'yup';
import { IconButton, Tooltip } from '@mui/material';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
//import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { useUpdateFeature } from 'services/useQueries';
//import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

const FeatureProperties = {
    id: 'id',
    name: 'name',
    featureKey: 'featureKey',
    activate: 'activate',
};

const ActivateFeature: ActionType = ({ config, id, data }: ActionTypeProps): JSX.Element => {
    const { mutateAsync: updateFeatureMutateAsync } = useUpdateFeature();

    const handleClick = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        await updateFeatureMutateAsync({ featureId: id, data: { activate: !data.activate } });
    };

    return id ? (
        <>
            <Tooltip title={`${data.activate ? 'Désactiver' : 'Activer'} ${config.label}`}>
                <IconButton
                    color={'info'}
                    aria-label={`${data.activate ? 'Désactiver' : 'Activer'} ${config.label}`}
                    onClick={handleClick}
                >
                    {data.activate ? <ToggleOnIcon color="success" /> : <ToggleOffIcon color="error" />}
                </IconButton>
            </Tooltip>
        </>
    ) : (
        <></>
    );
};

const Feature: ResourceType = {
    resourcePath: 'bo-features',
    placeHolder: {
        [FeatureProperties.name]: '',
        [FeatureProperties.featureKey]: '',
        [FeatureProperties.activate]: false,
    },
    validationSchema: yup.object({
        [FeatureProperties.name]: yup.string().required('Ce champ est obligatoire'),
        [FeatureProperties.featureKey]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Features',
        listInputs: [
            {
                label: 'Nom',
                property: FeatureProperties.name,
            },
            {
                label: 'Key',
                property: FeatureProperties.featureKey,
            },
            {
                label: 'Activer',
                property: FeatureProperties.activate,
                type: InputType.boolean,
            },
        ],
    },
    configList: {
        label: 'Features',
        listProperties: [
            {
                label: 'Nom',
                property: FeatureProperties.name,
                sortable: true,
                styles: {
                    minWidth: '25%',
                },
            },
            {
                label: 'key',
                property: FeatureProperties.featureKey,
                sortable: true,
                styles: {
                    minWidth: '25%',
                },
            },
            {
                label: 'Publié',
                property: FeatureProperties.activate,
                transform: BooleanConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
        customs: [
            {
                config: {
                    label: 'la feature',
                    link: `${process.env.REACT_APP_WWW_URL}/mes-recettes/recette?id=:id`,
                    icon: <ToggleOnIcon />,
                },
                component: ActivateFeature,
            },
        ],
    },
};

export type FeatureType = {
    id?: number;
    name: string;
    featureKey: string;
    activate: boolean;
};

export default Feature;
export { FeatureProperties };
