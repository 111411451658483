/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, Fragment, /*useContext, */ useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';

import { useGetAllResources } from 'm6BoBuilder/services/useQueries';
import { HydraBase } from 'm6BoBuilder/interfaces/hydra.interface';

type ValueType = { value: unknown; label: string };

type FormEntityPropsType = FormElementPropsType & { keepEntity?: boolean };

const FormEntity = ({
    entity,
    defaultValue,
    updateEntity,
    configLine,
    fieldSize = 'medium',
    fieldWidth = 'auto',
    keepEntity,
}: FormEntityPropsType): JSX.Element => {
    const resource: string = configLine.options?.resource ?? '';
    const entityIdentifier = configLine.options?.resourceIdentifier ?? '@id';

    if (!defaultValue) {
        defaultValue = entity[configLine.property];
    }

    // Hooks
    const [selectedValue, setSelectedValue] = useState<ValueType | ValueType[] | null>(
        configLine.options?.multiple ? [] : null,
    );
    const [defaultSelectedValue, setDefaultSelectedValue] = useState<ValueType | ValueType[] | null>(
        configLine.options?.multiple ? [] : null,
    );

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [listOptions, setListOptions] = useState<ValueType[]>([]);
    const [queryOptions, setQueryOptions] = useState<Record<string, any>>({});

    // QUERIES
    const {
        isFetching,
        //isSuccess,
        data: { list } = { list: [] },
    } = useGetAllResources(resource, queryOptions, configLine.options?.service);

    const convertListFormat = (list: HydraBase[]): ValueType[] => {
        return list.map((element: Record<string, any>): ValueType => {
            return convertFormat(element);
        });
    };

    const convertFormat = (element: Record<string, any>): ValueType => {
        let label = element[configLine.options?.label ?? 'title'];
        if (configLine.options?.details) {
            //const detail = resolve(configLine.options.details.path, element);
            const detail = element[configLine.options.details.path];
            const itemDetails = `${configLine.options?.details.prelabel}${
                configLine.options?.details.values ? configLine.options?.details.values[detail] : detail
            }`;
            label = `${label} (${itemDetails})`;
        }

        return {
            value: element[entityIdentifier],
            label: label,
        };
    };

    const handleChange = (_e: ChangeEvent<unknown>, value: ValueType | ValueType[] | null, reason: string) => {
        if (reason != 'clear') {
            const values = Array.isArray(value) ? value.map((element: ValueType) => element.value) : value?.value;
            const updatedEntity = {
                ...entity,
                [configLine.property]: value === null ? value : values,
            };
            updateEntity(updatedEntity, configLine.property);
            setSelectedValue(value);
        }
        if (reason === 'clear') {
            setSelectedValue(configLine.options?.multiple ? [] : null);
        }
    };

    const searchOptions = (searchParams?: any) => {
        let queryOptions = {};
        if (searchParams) {
            const { search = '', ...params } = searchParams;
            const options = search == '' ? {} : { [configLine.options?.label ?? 'title']: search };
            queryOptions = { ...params, ...options };
        }
        setQueryOptions(queryOptions);
    };

    const defaultOptions = (searchParams?: any) => {
        let queryOptions = {};
        if (searchParams) {
            const { search = '', ...params } = searchParams;
            const options = search == '' ? {} : { ['id']: search };
            queryOptions = { ...params, ...options };
        }
        setQueryOptions(queryOptions);
    };

    const checkDefaultValue = () => {
        return (
            (defaultValue &&
                (!defaultSelectedValue ||
                    (Array.isArray(defaultSelectedValue) && defaultSelectedValue.length === 0))) ||
            (Array.isArray(defaultValue) &&
                defaultValue.length !== 0 &&
                Array.isArray(defaultSelectedValue) &&
                defaultSelectedValue.length === 0)
        );
    };

    useEffect(() => {
        // Set default value with query params
        if (list && list.length > 0) {
            const listOptions = convertListFormat(list);
            setListOptions(listOptions);
        }
    }, [list]);

    useEffect(() => {
        // set default values
        if (checkDefaultValue()) {
            let cleanValues: any = null;
            const cleanDefaultValues = configLine.options?.multiple
                ? !Array.isArray(defaultValue)
                    ? [defaultValue]
                    : defaultValue
                : defaultValue;

            if (cleanDefaultValues.hasOwnProperty('@id')) {
                cleanValues = cleanDefaultValues['@id'];
            } else if (Array.isArray(cleanDefaultValues)) {
                cleanValues = cleanDefaultValues.map((value: any) => {
                    return value.hasOwnProperty('@id') ? value['@id'] : value;
                });
            }

            const defaultCleanedValue = Array.isArray(cleanValues)
                ? listOptions.filter((option: any) => cleanValues.includes(option.value))
                : listOptions.find((option: any) => option.value === cleanValues);

            if (!defaultCleanedValue) {
                defaultOptions({ search: cleanValues });
            } else if (Array.isArray(defaultCleanedValue) && defaultCleanedValue.length === 0) {
                setSelectedValue([]);
                defaultOptions({ search: cleanValues });
            }

            if (defaultCleanedValue) {
                setDefaultSelectedValue(defaultCleanedValue);
                setSelectedValue(defaultCleanedValue);
            }
        }
    }, [listOptions]);

    useEffect(() => {
        if (configLine.options?.disableAutocomplete !== true) {
            searchOptions({ search: inputValue });
        }
    }, [inputValue]);

    return (
        <Autocomplete
            id={configLine.label}
            sx={{ width: fieldWidth }}
            size={fieldSize}
            multiple={!!configLine.options?.multiple}
            readOnly={!!configLine.options?.readOnly}
            defaultValue={defaultSelectedValue}
            value={selectedValue}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option: ValueType, value) => option.value === value.value}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.label}
            options={listOptions || []}
            loading={isFetching}
            onChange={handleChange}
            onInputChange={(_event, newInputValue, reason: string) => {
                if (reason !== 'clear') {
                    setInputValue(newInputValue);
                } else {
                    setInputValue('');
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    //error={error}
                    label={configLine.label}
                    name={configLine.label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                    }}
                />
            )}
            disableClearable={configLine.options?.disableClearable}
        />
    );
};
export default FormEntity;
